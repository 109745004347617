import React from "react"
import SEO from '../components/seo'
import Background from '../components/Background'
import Wrapper from '../components/Wrapper'
import Heading from '../components/Heading'
import Grid from '../components/Grid'
import Menu from '../components/Menu'
import { useStaticQuery, graphql } from 'gatsby'

const items = [
  {
    title: 'Flutter Clock 2020',
    imageKey: 'flutter',
    href: 'https://flutter.dev/clock',
    info: '"Overall Execution" Winner'
  }, 
  { 
    title: 'AgriHack 2019',
    imageKey: 'agrihack',
    href: 'https://agrohub.bg/results-from-agritechhack-the-first-agrohackathon-in-bulgaria/?lang=en',
    info: 'First Place Winner'
  },
  {
    title: 'Imagine Cup 2014',
    imageKey: 'icup',
    href: 'https://www.scalefocus.com/news/scalefocus-supports-microsoft-imagine-cup-2014',
    info: 'Local Finals Winner'
  },
]



export default () => {
  const imagesData = useStaticQuery(graphql`
    query AwardImages {
      flutter: file(relativePath: {eq: "flutter.png"}) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      agrihack: file(relativePath: {eq: "agrihack.png"}) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      icup: file(relativePath: {eq: "icup.jpg"}) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <>
      <SEO
        title={ 'Brester - My Awards'}
        description={ 'Brester.tech awards' }
        lang={ 'en' }
      />
      <Background>
        <Wrapper>
          <Heading>Awards</Heading>
          <Grid
            items={ items }
            imagesData= { imagesData }
          />
          <Menu />
        </Wrapper>
      </Background>
    </>
  )
}