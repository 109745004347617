import React from "react"
import styled from 'styled-components'
import Img from 'gatsby-image'
import Paragraph from './Paragraph'
import InfoText from './InfoText'

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 60px; 
`

const Item = styled.a`
  display: block; 
  width: 100%; 
  text-decoration: none;
  z-index: 2;

  &:nth-child(n+2){
    margin-top: 50px;
  }

  @media(min-width: 480px){
    &&& {
      width: 50%;
      margin-top: 0; 
    }

    &&&:nth-child(n+3){
      margin-top: 50px;
    }
  }

  @media(min-width: 992px){
    &&&& {
      width: 33.33%;
      margin-top: 0; 
    }

    &&&:nth-child(n+4){
      margin-top: 50px;
    }
  }
`

const ItemPadding = styled.div`
  @media(min-width:480px){
    padding: 0 10px;
  }
`

const TitleWrapper = styled.div`
  margin-top: 20px;
  text-align: center;
`

const InfoWrapper = styled.div`
  margin-top: 5px; 
`


export default ({ items, imagesData }) => { 
  return (
    <Wrapper>
      { items.map((item, index) => {
        
        return ( 
        <Item
          key={ index }
          href={ item.href }
          rel="noreferrer noopener"
        >
          <ItemPadding>
            <Img fluid={ imagesData[item.imageKey].childImageSharp.fluid } />
            <TitleWrapper>
              <Paragraph>
                { item.title }
              </Paragraph>
            </TitleWrapper>
            <InfoWrapper>
              <InfoText>
                { item.info }
              </InfoText>
            </InfoWrapper>
          </ItemPadding>
        </Item>
        )
      })}
    </Wrapper>
  )
}